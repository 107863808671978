import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CANADVANCE_URL,
  NEURALIGN_ACADEMY_URL,
  NEURALIGN_USA_URL,
} from '../../../../../../../apps/lsworks/src/app/app.constants';
import { USCODE } from 'apps/lsworks/src/app/shared/services/country-handler-service/country-handler-constants';
import { CountryHandlerService } from 'apps/lsworks/src/app/shared/services/country-handler-service/country-handler.service';
import { SideMenuToggle } from '../+store/menu.actions';
import { Colour } from '../../../styles/colors';
import { headerButtons, websiteMenuItems } from '../constants/website-menu.constants';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  menuItems = { links: websiteMenuItems, buttons: headerButtons };

  width: 'mobile' | 'desktop' = 'desktop';

  @ViewChild('header') header: ElementRef<HTMLElement>;

  @HostListener('document:scroll', ['$event'])
  handleScroll(event) {
    const scroll = event.target.scrollingElement.scrollTop;
    if (scroll > 50) {
      this.header.nativeElement.style.backgroundColor = Colour.WHITE;
    } else {
      this.header.nativeElement.style.backgroundColor = Colour.TRANSLUCENT;
    }
  }

  @HostListener('window:resize')
  public getWidth() {
    if (window.innerWidth > 1360) {
      this.width = 'desktop';
    } else {
      this.width = 'mobile';
    }
  }

  constructor(private store: Store<any>, private router: Router, private countryHandler: CountryHandlerService) {
    this.getWidth();
  }

  public toggleSideMenu() {
    this.store.dispatch(SideMenuToggle());
  }

  public goHome() {
    this.router.navigate(['home']);
  }

  public goto(redirect) {
    if (redirect.includes('https')) {
      const orgUrl = this.countryHandler.userCountry === USCODE ? NEURALIGN_USA_URL : NEURALIGN_ACADEMY_URL;
      window.open(orgUrl, '_blank');
    } else {
      this.router.navigate([redirect]);
    }
  }
}
