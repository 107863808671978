// TODO add on Ingrid's dashboard a way to save which organization will receive the b2cs from non reserved regions

export const LSWORKS_REGISTER_URL = 'https://ls.works/authCallback?b2csignup=true&subdomain=neuralignacademy';
export const CANADVANCE_REGISTER_URL = 'https://ls.works/authCallback?b2csignup=true&subdomain=neuralignusa';

export const CANADVANCE = 'canadvance';
export const NEURALIGNUSA = 'neuralignusa';
export const NEURALIGN_ACADEMY = 'neuralignacademy';
export const CANADVANCE_URL = 'https://canadvance.ls.works/';
export const NEURALIGN_ACADEMY_URL = 'https://neuralignacademy.ls.works/';
export const NEURALIGN_USA_URL = 'https://neuralignusa.ls.works/';

export const DYSLEXIA_SCREENING_SURVEY_URL =
  'https://readls.surveysparrow.com/s/Neuralign-Dyslexia-Screening/tt-cfc32d?';

export const calculateSavingPercentage = (discountedPrice: number, fullPrice: number = 1500): number => {
  const savingAmount = fullPrice - discountedPrice;

  return Math.floor((savingAmount / fullPrice) * 100);
};
